body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (max-width: 768px) {
  .container{
      text-align: center;
      margin-top: 70px;
  }
}

/*Navbar*/

.navbar
{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  min-height: 70px;
}

.collapse .nav-link
{
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;;
  letter-spacing: 1px;
  font-weight:500;
  font-size:medium;
}

.collapse .nav-item .nav-link:hover
{
  color:#0039a6;
}

.container-fluid .navbar .navbar-brand
{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight:bolder;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #0039a6;
  letter-spacing: 1px;
}


/* Homepage.js */

.container
{
    margin-top: 100px;
    margin-bottom: 50px;
}

.logo
{
    width:100%;
    height: auto;
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.card-body .card-title
{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 4rem;
    font-weight: bolder;
    letter-spacing: 1px;
    background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #0039a6 67%,
    #228b22 100%
  );
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  animation-direction: reverse;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.card-body .card-text
{
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-size: 1.3rem;
}

.text 
{
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-size: 1.5rem;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {

    .container{
        margin-top:70px;
        margin-bottom: 0px;
    }
    .logo
    {
        margin-top: 0px;
    }
}

.login
{
    max-width: 25%;
    height: 125px;
}

@media only screen and (max-width: 768px) {

  .login{
      max-width: 25%;
      height: min-content;
  }
}


/* CSS For Footer */

.footer
{
  min-height: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}


.list-TC
{
  list-style-type: none;
  justify-content: center;
}

.list-TC li a
{
  text-decoration: none;
}

.listIcon
{
  list-style-type: none;
  justify-content: center;
}


.sm-icon
{
    width:auto;
    height:30px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .list-TC, .listIcon{
    margin-right: 25px;
  }

  .footer{
    margin-top: 0px;
  }

}

/* About Us page */

.AboutImg{
  width: 100%;
  height: 40%;
}

.cardEffect
{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: none;
}

@media only screen and (max-width: 768px) {
  .cardCol{
    margin-bottom: 50px;
  }
}

/*Contact Us */

.ContImg
{
    width:80%;
    height: auto;
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
}

.contact
{
  margin-top: 120px;
}

@media only screen and (max-width: 768px) {
  .contact{
    text-align: start;
    margin-top: 100px;
    justify-content: center;
  }

  .ContImg{
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

.form-control
{
  line-height: 40px;
  width: 100%;
  height: auto;
  border: 0px;
  background-color: #EEEEFF;
}

form input::placeholder
{
  font-size:medium;
}

.cardCont
{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: none;
  line-height: auto;
}

.ContCardImg
{
  width: 20%;
  height: auto;
}

.cardCont a
{
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .detail{
    margin-bottom: 50px;
  }
}


/* Terms & Conditions */

.contTerm
{
  margin: 90px 0px 30px 0px;
}

.content
{
  padding: 0px 20px 0px 20px;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .content{
    padding: 0px 10px 0px 10px;
    text-align: justify;
  }

  .contTerm
  {
    margin-bottom: 10px;
  }
}

/* Scroll to Top Button */

.topBtn{
  position: relative;
}

.iPos{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.iStyle{
  background-color: #0039a6;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: backTop 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.iStyle:hover{
  animation: none;
  background: #fff;
  color: #0039a6;
  border: 2px solid #0039a6;
}

@keyframes backTop {
  0%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(0px);
  }
}